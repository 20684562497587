import React from 'react'
import { graphql } from 'gatsby'

import Seo from 'components/SEO'
import PageContent from 'components/Shared/_PageContent'
import Title from 'components/Shared/Title'
// import { ButtonExtLink } from 'components/Shared/_Links'

import JoinNav from 'components/Public/JoinNav'
import JoinFootnote from 'components/Public/JoinFootnote'

// ======================================
// use this when poster is image format
// ======================================
// import { Image } from 'components/Shared/Image'
// import IFrame from 'components/Shared/IFrame'

// ======================================
// use this when poster is PDF format
// ======================================
// import preClass from 'assets/files/join_pre.pdf'

import styled from 'styled-components'
const Wrapper = styled.div`
  min-height: 100%;
`
// const Poster = styled.div`
//   --width: 91%;
//   --height: 54vh;

//   ${p => p.theme.mq.xs`
//     --height: 66vh;
//   `}
//   ${p => p.theme.mq.sm`
//     --height: 95vh;
//   `}
//   ${p => p.theme.mq.md`
//     --height: 105vh;
//   `}
//   ${p => p.theme.mq.lg`
//     --height: 125vh;
//   `}

//   width: var(--width);
//   height: var(--height);
//   margin: 0 auto;
// `

const Pre = ({ data, location }) => {
  const { seo } = data
  // const { seo, imagefile: { childImageSharp: image } } = data
  const { title, description } = seo.frontmatter.joinPre

  return (
    <PageContent>
      <Seo title={title} description={description} />
      <JoinNav location={location} />
      

      <Wrapper>
        <Title>
          {/* 晶晶小小班 <span>招生訊息</span> */}
          晶晶小小班<span> 目前無招生 </span>
          {/* <span>招收對象: 4到6歲 (滿四歲，2017.3.1 前出生之幼兒)</span> */}
        </Title>

        {/* <ButtonExtLink href='http://goo.gl/7A39Vc' target='_blank' rel='noreferrer' style={{ marginBottom:'2.2rem'}}>
          我要報名
        </ButtonExtLink> */}
        
        {/* <Image image={image} alt='晶晶小小班招生訊息' /> */}
        {/* <Poster> */}
          {/* <embed
            src={`${preClass}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&view=Fit`}
            type='application/pdf'
            width='100%'
            height='100%'
          /> */}
          {/* <IFrame src={regularClass} /> */}
        {/* </Poster> */}

        <JoinFootnote />
      </Wrapper>
    </PageContent>
  )
}

export default Pre

export const query = graphql`
  {
    seo: mdx(frontmatter: { type: { eq: "seo" } }) {
      frontmatter {
        joinPre {
          title
          description
        }
      }
    }
    imagefile: file(name: { eq: "2022_join_pre" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`
